import React from 'react';
import Link from 'gatsby-link';
import { Box, Flex, Heading, Text } from 'rebass/styled-components';

export default function AboutUs() {
  return (
    <Flex
      flexDirection={'column'}
      variant={'wrapper'}
      marginTop={[20, 40]}
      color={'text'}
    >
      <Box variant={'borderBox'}>
        <Box padding={[30, 60]}>
          <Heading
            marginLeft={20}
            mb={[25, 50]}
            fontSize={[24, 32]}
            fontFamily={'Oswald'}
          >
            O nas
          </Heading>
          <Text
            margin={20}
            fontSize={[16, 20]}
            fontFamily={'OpenSans'}
            lineHeight={2.5}
            whiteSpace={'nowrap'}
          >
            Z wielką radością informujemy, że od 1 lutego 2021r. przy ECRK Lex
            rozpoczął funkcjonowanie   OŚRODEK DOSKONALENIA NAUCZYCIELI
            „PROLEGIS" ELŻBIETA LINOWSKA. Jest to placówka doskonalenia
            nauczycieli o zasięgu ogólnopolskim.
            <br />
            Posiada on akredytację Podlaskiego Kuratora Oświaty i jest wpisany
            do Rejestru Szkół i Placówek Oświatowych SIO - numer RSPO 276980
            <br />
            Akredytacja Kuratora Oświaty jest rękojmią, że nasze szkolenia
            prowadzi wykwalifikowana kadra, posiadająca przygotowanie zawodowe i
            edukatorskie do kształcenia nauczycieli. <br />
            Pani Elżbieta Linowska jako organ prowadzący dodatkowo zapewnia
            wysoką jakość merytoryczną, potwierdzaną od lat przez uczestników
            szkoleń. <br />
            Uzyskana akredytacja jest dla Państwa m.in. gwarancją, że szkolenia
            realizowane przez nasz zespół spełniają wymogi wynikające z{' '}
            <Link to={'/'}>
               Rozporządzenia Ministra Edukacji Narodowej z dnia z dnia 23
              sierpnia 2019 r. w sprawie dofinansowania doskonalenia zawodowego
              nauczycieli 
            </Link>
            (można je także finansować ze środków publicznych przeznaczonych na
            doskonalenie nauczycieli).
          </Text>
          <Text
            marginTop={10}
            fontSize={[12, 16]}
            fontFamily={'OpenSans'}
            lineHeight={2}
            whiteSpace={'nowrap'}
          >
            Copyright (C) 2021 Korzystanie z materiałów redakcyjnych ODN
            "Prolegis" Elżbieta Linowska wymaga wcześniejszej zgody oraz
            zawarcia stosownej umowy licencyjnej. Na podstawie art. 25 ust. 1
            pkt. 1 b) ustawy o prawie autorskim i prawach pokrewnych ODN
            "Prolegis" Elżbieta Linowska wyraźnie zastrzega, że dalsze
            rozpowszechnianie treści zamieszczonych na stronie internetowej jest
            zabronione.
            <br />
            <br />
            <a href={'decyzja-kuratorium.pdf'} download>
              Decyzja kuratorium - akredytacja
            </a>
            <br />
            <a href={'System Informacji Oświatowej - Prolegis.pdf'} download>
              System Informacji Oświatowej - Prolegis{' '}
            </a>
            <br />
            <a href={'Zaświadczenieo o wpisie z UW.pdf'} download>
              Zaswiadczenie o wpisie z UW
            </a>
          </Text>
        </Box>
      </Box>
    </Flex>
  );
}
