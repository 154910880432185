import React from 'react';
import Layout from '../components/Layout';
import AboutUs from '../components/AboutUs';

const About = () => {
  return (
    <Layout>
      <AboutUs />
    </Layout>
  );
};

About.propTypes = {};

About.defaultProps = {};

export default About;
